@import "../../styles/base.scss";

.contentBlock {
  @extend %content-block;
}

.wrapper {
  width: 100%;
  height: $sub-nav-height;
  background: $color-mid-7;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: $space-4;
    height: 100%;
    background: linear-gradient(
      to right,
      transparentize($color-mid-7, 1),
      $color-mid-7
    );
    pointer-events: none;
    @include breakpoint(sm) {
      display: none;
    }
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include breakpoint(sm) {
    justify-content: center;
  }
  @include breakpoint(md) {
    overflow: auto;
  }
}

.navItem {
  padding: 0 $space-3;
  font-size: $font-size-2;
  white-space: nowrap;
}

.navLink {
  color: $color-mid-1;
  text-decoration: none;
  transition: color $ease-out $trans-normal;
  cursor: pointer;
  &.active {
    opacity: 0.6;
  }
  &:hover {
    color: $color-primary;
  }
}
