$color-primary: #09afdf;
$color-background: #fff;
$color-foreground: #000;
$color-highlight: #fe019a;
$color-error: mix($color-highlight, red, 65%);

$color-gold: #c59438;
$color-silver: #c3bdb3;
$color-bronze: #965a39;

$color-mid-1: mix($color-foreground, $color-background, 5%);
$color-mid-2: mix($color-foreground, $color-background, 13%);
$color-mid-3: mix($color-foreground, $color-background, 23%);
$color-mid-4: mix($color-foreground, $color-background, 35.5%);
$color-mid-5: mix($color-foreground, $color-background, 50%);
$color-mid-6: mix($color-foreground, $color-background, 64.5%);
$color-mid-7: mix($color-foreground, $color-background, 77%);
$color-mid-8: mix($color-foreground, $color-background, 87%);
$color-mid-9: mix($color-foreground, $color-background, 95%);

/* Generic Colors */
$color-white: #fff;
$color-black: #000;

/* Spacing */
$space-1: 4px;
$space-2: 8px;
$space-3: 16px;
$space-4: 32px;
$space-5: 64px;
$space-6: 128px;
$space-7: 256px;
$space-8: 512px;
$space-9: 1024px;

/* Font Sizes */
$font-size-1: 12px;
$font-size-2: 14px;
$font-size-3: 16px;
$font-size-4: 20px;
$font-size-5: 24px;
$font-size-6: 32px;
$font-size-7: 48px;
$font-size-8: 64px;
$font-size-9: 96px;

/* Font Stacks */
$font-stack-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
$font-stack-monospace: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;

/* Headings */
$font-heading-name: "Raleway";
$font-heading-weight: 500;
$font-heading-spacing: auto;
$font-heading-size: $font-size-3;
$font-heading-transform: auto;
$font-heading-line-height: 1.4;

$font-heading: $font-heading-name, $font-stack-sans-serif;

/* Body */
$font-body-name: "Roboto";
$font-body-weight: 400;
$font-body-spacing: auto;
$font-body-size: $font-size-3;
$font-body-line-height: 1.4;

$font-light-weight: 300;
$font-heavy-weight: 700;

$font-body: $font-body-name, $font-stack-sans-serif;

/* Code */

$font-code: $font-stack-monospace;

/* Breakpoints */
$breakpoint-sm: 600px;
$breakpoint-md: 800px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1120px;

$breakpoint-mobile: $breakpoint-lg;

/* Transitions */
$ease-in-out: ease-in-out;
$ease-out: ease-out;
$ease-in: ease-in;

$trans-super-fast: 0.05s;
$trans-fast: 0.1s;
$trans-normal: 0.3s;
$trans-slow: 0.9s;
$trans-extra-slow: 1.2s;

/**/

$global-nav-height: $space-5;
$sub-nav-height: $space-5 * 0.75;
