%heading {
  font-family: $font-heading;
  font-weight: $font-heading-weight;
  letter-spacing: $font-heading-spacing;
  text-transform: $font-heading-transform;
  line-height: $font-heading-line-height;
}

%body {
  font-family: $font-body;
  font-weight: $font-body-weight;
  letter-spacing: $font-body-spacing;
  font-size: $font-body-size;
  color: $color-mid-7;
  line-height: $font-body-line-height;
}

%content-block {
  width: 100%;
  max-width: 1024px; // Equal to the large breakpoint
  margin: auto;
  padding-left: $space-3;
  padding-right: $space-3;
  box-sizing: border-box;
}

%link {
  transition: color $trans-normal $ease-out;
  &:hover {
    color: $color-primary;
  }
}

%no-selection {
  ::selection {
    background: transparent;
  }
}

%markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend %heading;
    margin: $space-3 0;
  }
  p {
    @extend %body;
    margin: $space-2 0;
  }
  a {
    color: $color-primary;
    transition: opacity $ease-out $trans-normal;
    &:hover {
      opacity: 0.6;
    }
  }
  img {
    width: 100%;
    max-height: 60vh;
    max-width: 100%;
    object-fit: contain;
    margin: $space-3 0;
    
  }

  img[alt=bigImage]{
    width: 100%;
    max-width: 100%;
    max-height: none;
    object-fit: contain;
    margin: $space-3 0;
  }
}
