@mixin breakpoint($point: mobile) {
  @if $point == xl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $point == xs {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $point == mobile {
    @media (min-width: $breakpoint-mobile) {
      @content;
    }
  }
}
