@import "../../styles/base.scss";

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.appLocked {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wrapper {
  @extend %body;
  font-size: 16px;
  width: 100%;
  background: $color-background;
  height: $global-nav-height;

  /* Copies of global styles */

  &,
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [data-js-focus-visible] & :focus:not([data-focus-visible-added]) {
    outline: none;
  }

  [data-js-focus-visible] & [data-focus-visible-added]:focus {
    outline-color: mix($color-highlight, transparent, 40%);
  }

  ::selection {
    background-color: mix($color-highlight, transparent, 30%);
  }
}

.navBackground {
  background: $color-background;
  height: $global-nav-height;
  z-index: 98;
  width: 100%;
  border-bottom: 1px solid $color-mid-1;
}

.mobileBar {
  z-index: 101;
  width: 100%;
  top: 0;
  position: fixed;
  height: $global-nav-height;
  border-bottom: 1px solid $color-mid-1;
  background: transparent;
  transition: background-color $ease-out $trans-slow;
  .open & {
    transition: background-color $ease-out $trans-fast;
    background: $color-background;
  }
  @include breakpoint(mobile) {
    display: none;
  }
}

.mobileLogo {
  width: $global-nav-height;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .lanternInner {
    transition: fill $ease-out $trans-normal;
    fill: $color-mid-5;
  }
}

.mobileMenuButton {
  appearance: none;
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: $global-nav-height;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  .buttonLine {
    position: absolute;
    width: $global-nav-height - ($space-3 + $space-1) * 2;
    height: 3px;
    left: ($space-3 + $space-1);
    top: calc(50% - #{3px * 0.5});
    transition: transform $ease-out $trans-normal * 0.5 $trans-normal * 0.5;
    &:before {
      position: absolute;
      content: "";
      background: $color-mid-4;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: transform $ease-out $trans-normal * 0.5;
    }
    &:first-child {
      transform: translate3d(0, -175%, 0);
    }
    &:last-child {
      transform: translate3d(0, 175%, 0);
    }
  }

  .open & {
    .buttonLine {
      transition: transform $ease-out $trans-normal * 0.5;
      transform: translate3d(0, 0, 0);
      &:before {
        transition: transform $ease-out $trans-normal * 0.5 $trans-normal * 0.5;
      }
      &:first-child:before {
        transform: rotate(45deg);
      }
      &:last-child:before {
        transform: rotate(-45deg);
      }
    }
  }
}

.nav {
  position: fixed;
  border-bottom: 1px solid transparent;
  width: 100%;
  height: 100vh;
  z-index: 100;
  padding-top: $global-nav-height;
  background: $color-background;
  transform: translate3d(0, -100%, 0);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  transition: none;
  top: 0px;
  bottom: 0px;
  .dirty & {
    transition: transform $ease-out $trans-normal;
  }
  .open & {
    transform: translate3d(0, 0, 0);
  }
  @include breakpoint(mobile) {
    overflow: unset;
    padding: 0;
    transform: translate3d(0, -100%, 0);
    top: $global-nav-height;
    opacity: 1;
    height: $global-nav-height;
    background: transparent;
    transition: none;
    .dirty & {
      transition: none;
    }
    .open & {
      transform: translate3d(0, -100%, 0);
    }
  }
}

.content {
  @extend %content-block;
  display: flex;
  flex: 2;
  @include breakpoint(mobile) {
    max-width: $space-9 * 0.8;
  }
  @include breakpoint(xl) {
    max-width: $space-9;
  }
}

.navItems {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: $space-4 $space-3;
  @include breakpoint(mobile) {
    justify-content: space-between;
    margin: auto;
    padding: 0 $space-5 * 1.5;
    flex-direction: row;
    height: $global-nav-height;
  }
}

.navItem {
  display: flex;
  align-items: center;
  padding: $space-2 + $space-1 0;
  @include breakpoint(mobile) {
    padding: 0;
  }
}

.navLink {
  @extend %heading;
  text-decoration: none;
  font-size: $font-size-4;
  color: $color-mid-7;
  text-align: center;
  transition: color $ease-out $trans-normal;
  .lanternInner {
    transition: fill $ease-out $trans-normal;
    fill: $color-mid-5;
  }
  &:hover {
    color: $color-primary;
    .lanternInner {
      fill: $color-primary;
    }
  }
  text-align: center;

  @include breakpoint(mobile) {
    font-size: $font-size-3;
  }
}

.desktopLogo {
  display: none;
  @include breakpoint(mobile) {
    display: flex;
  }
}

.lantern {
  width: $space-3;
}

.specialLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $space-4 $space-3;
  padding-top: 0;
  opacity: 0;
  transition: opacity $trans-normal $ease-in-out;
  .navLink {
    text-transform: uppercase;
    border: 2px solid $color-mid-2;
    color: $color-mid-4;
    font-size: $font-size-2;
    font-weight: $font-heavy-weight;
    letter-spacing: 0.05em;
    padding: 0 $space-3 * 0.8;
    height: $space-4;
    border-radius: $space-4 * 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: $space-8;
    transition: color $trans-normal $ease-out,
      border-color $trans-normal $ease-out;
    &:hover {
      color: $color-primary;
      border-color: $color-primary;
    }
  }
  @include breakpoint(mobile) {
    opacity: 1;
    height: $global-nav-height;
    padding: 0 $space-3;
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
    .navLink {
      font-size: $font-size-1;
      height: $space-4 * 0.9;
      border-radius: $space-4 * 0.9 * 0.5;
    }
  }
  .open & {
    opacity: 1;
  }
}
