/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-family: Raleway;
  font-weight: 500;
  &:hover {
    .dropdownContent {
      display: block;
    }
    color: #09afdf;

    .dropBtn {
      color: #09afdf;
    }

    &:after {
      height: 32px;
    }
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 230px;
    &.dropdownLast {
      transform: translateX(-50%);
      left: -50%;
    }
    &.dropdownFirst {
      transform: translateX(50%);
      left: 50%;
    }
    top: 64px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 30000;
    padding: 6px;
    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #ddd;
      }
    }
  }

  @media (max-width: 768px) {
    .dropBtn {
      font-size: 20px;
    }
    .dropdownContent {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 230px;
      &.dropdownLast {
        transform: translateX(-50%);
        left: 50%;
      }
      &.dropdownFirst {
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }
  /* Dropdown Button */
  .dropBtn {
    color: #3b3b3b;
    border: none;
    background: none;
    font-weight: 500;
    cursor: pointer;
    vertical-align: center;
    height: 64px;
    line-height: 64px;
    text-decoration: none;
    &:hover {
      color: #09afdf;
    }
    transition-duration: 300ms;
    transition-delay: 0s;
    transition-property: color;
    transition-timing-function: ease-out;
  }
}
